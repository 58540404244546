import "./App.css";
import { useEffect, useState } from "react";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import axios from "axios";
function App() {
  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const [dataWithToken, setDataWithToken] = useState();
  const [isLoading, setIsloading] = useState(false);

  const changeHandler = (event) => {
    setcsvData([]);
    setValues();
    setDataWithToken();
    setIsloading(true);
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        console.log("results.data", results);
        if (Object.keys(results.data[0]).length === 13) {
          const rowsArray = [];
          const valuesArray = [];
          // Iterating data to get column name and their values
          results.data.map((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });
          // Filtered Column Names
          rowsArray[0].push("Meeting URL", "Tokenize URL");
          setTableRows(rowsArray[0]);

          // Filtered Values
          setValues(valuesArray);
        } else {
          alert("Please upload correct file");
          setcsvData([]);
          setValues();
          setDataWithToken();
          setIsloading(false);
        }
      },
    });
  };

  const fetchTokens = async () => {
    try {
      let allUserData = [];
      const pages = Math.ceil(values.length / 20);
      for (let i = 0; i < pages; i++) {
        const userDataPage = values.slice(i * 20, (i + 1) * 20);
        const usersDetails = await Promise.all(
          userDataPage?.map((userData, index) => {
            return new Promise(async (resolve, reject) => {
              try {
                console.log(
                  "userData",
                  Array.isArray(userData[userData.length - 1])
                );
                const participantName = `${userData[3]} ${userData[4]}`;
                const roomName = Array.isArray(userData[userData.length - 1])
                  ? userData[userData.length - 1][0]?.split("id=")[1]
                  : userData[userData.length - 1]?.split("id=")[1];
                if (!roomName || roomName == "") {
                  resolve([
                    ...userData,
                    "",
                    "Error: room_id not found in request",
                  ]);
                  return;
                }
                const apiUrl = `${process.env.REACT_APP_USER_API_URL}/sales-api/create-token`;
                // const apiUrl = "http://localhost:4000/sales-api/create-token";
                const res = await axios.post(apiUrl, {
                  room_id: roomName,
                  user_name: participantName,
                  email: userData[5],
                });
                const body = {
                  room: `NGTest${index}`,
                  participantName: "Nilesh Gawande",
                };
                const tokenRes = await axios.post(
                  `${process.env.REACT_APP_ADMIN_API_URL}/api/token`,
                  body
                );
                const token = tokenRes.data.token;
                const metUrl =
                  `${process.env.REACT_APP_USER_API_URL}/` +
                  res.data.data.room_id;

                const tokenizeURL =
                  `${process.env.REACT_APP_USER_API_URL}/${body.room}?jwt=` +
                  token;
                resolve([...userData, metUrl, tokenizeURL]);
              } catch (error) {
                console.log("ERROR", error);
                resolve([...userData, "Error", JSON.stringify(error)]);
              }
            });
          })
        );
        allUserData = [...allUserData, ...usersDetails];
        setDataWithToken(allUserData);
      }
      console.log("End");
      setIsloading(false);
    } catch (error) {
      console.log("ERROR", error);
      setIsloading(false);
      alert(`Something went wrong: ${JSON.stringify(error)}`);
    }
  };

  useEffect(() => {
    if (values?.length > 0) {
      fetchTokens();
    }
  }, [values]);

  useEffect(() => {
    if (dataWithToken?.length > 0) {
      const csvDataL = [tableRows, ...dataWithToken];
      setcsvData(csvDataL);
    }
  }, [dataWithToken]);

  return (
    <div className="main-div">
      <div
        style={{ width: "100%", padding: "15px 0px", backgroundColor: "#fff" }}
      >
        <img
          src={"/logo.png"}
          alt="logo"
          style={{ width: "9.9rem", marginLeft: "2.25rem" }}
        ></img>
      </div>
      <div className="main-table-div">
        <span>Please select the CSV file, to generate the meeting links.</span>
        <a href={"/sample.csv"} download="SampleCSV" target="_blank">
          <button className="btn-download">(Download Sample CSV)</button>
        </a>
        <div style={{ display: "flex", marginTop: "0.7rem" }}>
          {/* File Uploader */}
          <input
            type="file"
            name="file"
            onChange={changeHandler}
            accept=".csv"
            style={{ textAlign: "left", display: "block", margin: "" }}
          />
        </div>
        {isLoading && <h5 style={{ textAlign: "center" }}>Loading...</h5>}
        <br />
        {/* Table */}
        <div className="table-div">
          <table>
            <thead>
              <tr>
                {tableRows?.map((rows, index) => {
                  return <th key={index}>{rows}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {dataWithToken?.map((value, index) => {
                return (
                  <tr key={index}>
                    {value.map((val, i) => {
                      return <td key={i}>{val}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
        {csvData && values?.length > 0 && isLoading === false && (
          <div className="export-csv-btn-div">
            <CSVLink
              className="downloadbtn"
              filename="meeting_list.csv"
              data={csvData}
            >
              Export to CSV
            </CSVLink>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
